<template>
  <div>
    <div class="page-title pageheading" style="height: 50px">
      <h3 class="titlename">
        <i class="fas fa-user"></i
        ><span class="ml-3">Client Authorization Expiration</span>
        <span style="font-size: 1rem; margin-left: 0.5rem"
          >(Authorizations ending within 45 days)</span
        >
      </h3>
      <span class="float-right green-btn">
        <a class="btn btn-success mr-3" @click="htmlToCSV"
          ><span><i class="material-icons">add</i> Download CSV</span></a
        >
      </span>
    </div>

    <div
      v-if="!isfetching"
      class="container mt-10"
      style="background-color: #ffffff"
    >
      <b-table
        class="table table-bordered"
        show-empty
        :items="getAuthorizationReport"
        select-mode="single"
        :fields="fields"
        :per-page="0"
        selectable
        striped
        sort-by="lastName"
        hover
      >
        <template #cell(endDate)="row">
          {{ row.item.endDate.split("T")[0] }}
        </template>
      </b-table>
    </div>
    <div
      v-else
      class="container mt-10"
      style="background-color: #ffffff; text-align: center"
    >
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      name: "",
      showmark: "true",
      showdiv: false,
      title: "",
      selectedCode: null,
      e6: [],
      e7: [],
      e5: [],
      e8: [],
      e9: [],
      caregiverstatus: null,
      matchingcodeoptions: [
        { value: null, text: "Please select matching code" },
        { value: "all", text: "Select All" },
        { value: "Compatible", text: "Compatible" },
        { value: "Not Prefered", text: "Not Prefered" },
        { value: "Prefered", text: "Prefered" },
        { value: "Staffed", text: "Staffed" },
      ],
      caregiverStatusoptions: [
        { value: "all", text: "Select All" },
        { value: "active", text: "Active" },
        { value: "inactive", text: "Inactive" },
        { value: "other", text: "Other" },
        { value: "terminated", text: "Terminated" },
      ],
      fields: [
        {
          key: "clientName",
          label: "Client Name",
        },
        {
          key: "service",
          label: "Service",
        },
        {
          key: "authorizationNumber",
          label: "Authorization Number",
        },
        {
          key: "endDate",
          label: "End Date",
        },
      ],
    };
  },
  created() {
    this.name = this.$route.params.text;
    this.title = this.$route.params.title;
  },
  computed: {
    ...mapGetters(["getAuthorizationReport"]),
  },
  mounted() {
    this.$store.dispatch("GetAuthorizationReport");
  },
  methods: {
    htmlToCSV() {
      var filename = "AuthorizationReport.csv";
      var data = [];
      var header = "";

      header = `"Client Name","Service","Authorization Number","End Date"`;
      data.push(header);

      this.getAuthorizationReport.forEach((ele) => {
        var row = `"${ele.clientName}","${ele.service}","${
          ele.authorizationNumber
        }","${ele.endDate.split("T")[0]}"`;
        data.push(row);
      });
      console.table(data);

      this.downloadCSVFile(data.join("\n"), filename);
    },
    downloadCSVFile(csv, filename) {
      var csv_file, download_link;

      csv_file = new Blob([csv], { type: "text/csv" });

      download_link = document.createElement("a");

      download_link.download = filename;

      download_link.href = window.URL.createObjectURL(csv_file);

      download_link.style.display = "none";

      document.body.appendChild(download_link);

      download_link.click();
    },
  },
};
</script>
<style scoped>
.titlename {
  float: left !important;
  padding-left: 19px;
}
.pageheading {
  padding-top: 20px;
  padding-bottom: 50px;
  height: 90px;
  background-color: #ffffff;
}
.panel-body {
  background-color: #ffffff !important;
  padding: 10px;
  margin-top: 20px;
}
strong {
  padding-top: 10px;
}

#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

.v-data-table tbody tr:nth-child(even) {
  background-color: #220c0c;
}

v-data-table tbody tr:hover {
  background-color: rgb(0, 0, 0);
}
.spanstar {
  right: 10px;
  top: 10px;
}
.rsmItem a.rsmLink {
  background: #fff;
  padding: 7px;
  border: 1px solid #ddd;
  margin: 7px;
  display: block;
  box-shadow: 0 3px 0 #ddd;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  border-radius: 5px;
  position: relative;
}
.rsmItem a.rsmLink:hover {
  background: #f8f8f8;
  box-shadow: 0 3px 0 #0284d0;
}
.org-checked {
  color: orange;
}
.org-unchecked {
  color: lightgray;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #0b0f0e7a;
  color: white;
}
/*.tableheader{
    padding-left: 2pt;
    padding-top: 2pt;
    padding-right: 2pt;
    padding-bottom: 2pt;
    border-bottom: 1pt solid #d3d3d3;
    border-left: 1pt solid #d3d3d3;
    border-right: 1pt solid #d3d3d3;
    border-top: 1pt solid #d3d3d3;
    background-color: rgb(220,220,220);
    vertical-align: middle;
    text-align: left;
    direction: ltr;
    writing-mode: lr-tb;
    min-width: 42.64mm;
    WIDTH: 44.75mm;
} */
</style>
